@font-face {
  font-family: 'splunk_data_sans';
  font-style:normal;
  font-weight:normal;
  src: url('https://www.splunk.com/etc/clientlibs/splunk/splunk-core/source/fonts/splunkdatasans/woff2/splunkdatasans_w_rg.woff2') format('woff2'), url('../../fonts/splunk_data_sans/splunkdatasans_w_rg.woff') format('woff');
}

@font-face {
  font-family: 'splunk_data_sans';
  font-weight:bold;
  src: url('https://www.splunk.com/etc/clientlibs/splunk/splunk-core/source/fonts/splunkdatasans/woff2/splunkdatasans_w_bd.woff2') format('woff2'), url('../../fonts/splunk_data_sans/splunkdatasans_w_bd.woff') format('woff');
}

body {
  font-family: 'splunk_data_sans', Arial, Helvetica, sans-serif !important;
}

.splunk-logo img {
  height: 36px;
}

.splunk-logo {
  padding-top: 0;
  margin-bottom: 16px;
  text-align: center;
}

.splunk-form .re-captcha {
  margin: 0!important;
  height: 0!important;
}

.loginFooter {
  font-family: 'splunk_data_sans';
}

.loginFooter:first-of-type {
  padding-bottom: 20px;
  border-bottom: 1px solid #D5DCE5;
  margin-bottom: 20px;
}

.loginFooter:last-of-type {
  padding-bottom: 0;
}

.splunk-btn.sp-btn-borderless {
  display: inline-block;
  border-radius: 0;
  background-color: transparent !important;
  border-color: transparent !important;
  color: #ED0080;
  font-weight: 700 !important;
  white-space: unset;
  line-height: 17px;
  min-width: auto;
  text-align: left;
  padding: 13px 0 14px 0 !important;
  border: none;
  margin: 0 !important;
  margin-right: 0 !important;
}

.splunk-btn.sp-btn-borderless::after {
  position: relative;
  content: '';
  font-family: splunk_list, Arial, Helvetica, sans-serif;
  font-size: 8px;
  font-weight: 700;
  color: #ED0080;
  bottom: 1px;
  left: 0;
  margin-left: 4px;
  background-repeat: no-repeat;
  width: 10px;
  height: 7px;
  display: inline-block;
}

.gzglyb[data-size="large"] {
  min-height: 42px;
}

.danxYD {
  margin-bottom: 0;
  line-height: 0;
  font-size: 0;
}

#content .eIyGjb {
  width: 100%!important;
  min-width: 100%;
}

.links .black-theme-form-container.long a {
  display: block;
}

.links .black-theme-form-container.long .body-small {
  padding-bottom: 8px;
}

@media screen and (min-width: 768px) {
  .col-md-offset-1 {
      margin-left: 8.33333333%;
  }
  .col-md-6 {
      width: 50%;
  }

  .col-md-4 {
      width: 33.33333333%;
  }

  .col-md-6,
  .col-md-4 {
      float: left;
  }
}

@media screen and (min-width: 768px) {
  .desktop-only {
      display: block;
  }

  .mobile-only {
      display: none;
  }

  .page-body {
      padding-top: 0;
  }

  #titleHead.splunk2-h4 { 
      font-size: 21px;
  }

  #content .body-small {
      font-size: 15px;
  }

  .splunk-btn.sp-btn-borderless {
      margin-right: 10px;
  }
}

#onetrust-banner-sdk {
  height: inherit !important;
}

#onetrust-banner-sdk #onetrust-policy-text {
  clear: both;
  text-align: left;
  font-size: .88em !important;
  line-height: 1.4 !important;
}

.login-background {
  background-image: url(../../Images/login-bkgd.svg);
  background-size: cover;
  background-position: center center;
  overflow-y: auto;
}

 body > div.wrapper {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-image: url(../../Images/login-bkgd.svg);
  background-size: cover;
  background-position: center center;
  overflow-y: auto;
}

div.wrapper footer.page-footer {
  flex-shrink: 0;
  background-color: #f0f3f7;
  padding: 15px 0 16px 0;
  font-size: 14px;
  font-family: 'splunk_data_sans',Helvetica,Arial,sans-serif;
  line-height: 1.42857143;
  -webkit-font-smoothing:antialiased;
  -moz-osx-font-smoothing:grayscale;
}
div.wrapper main.page-body {
  flex-grow: 1;
}
footer.page-footer .footer-content,
footer.page-footer .copyright-content {
  color: inherit;
  margin: 0 auto;
  max-width: 1110px;
  text-align: right;
  margin-bottom: 10px;
  font-weight: 400;
  line-height: 25px;
  font-family: 'splunk_data_sans' !important;
}

footer.page-footer .footer-content .footer-link {
  margin-left: 20px;
  text-align: right;
}

input,
select,
textarea,
label {
  font-size: 12px;
}

.standardForm .inputError {
  border-color: #e64b3c;
}

.standardForm label {
  display: block;
  padding-top: 12px;
}

.standardForm label span {
  padding: 0;
  display: block;
}

.standardForm .inputBox,
.standardForm .inputError,
.standardForm select {
  margin: 3px 0 0 0;
}

.standardForm .inputBox,
.standardForm .inputError {
  width: 275px;
}

.standardForm select,
.standardForm select.inputError {
  width: 281px;
  padding: 2px;
  border: 1px solid #ccc;
  border-top: 1px solid #666;
  border-left: 1px solid #666;
}

#expertForm .inputBox,
#expertForm .inputError {
  width: 375px;
}

#expertForm select,
#expertForm select.inputError {
  width: 381px;
}

#expertForm textarea {
  width: 375px;
  height: 250px;
  padding: 2px;
  border: 1px solid #ccc;
  border-top: 1px solid #666;
  border-left: 1px solid #666;
}

div.accountCreateDelimiter {
  border-top: 1px dotted #ccc;
  margin-top: 10px;
  padding-top: 5px;
}

#accountTypeHint {
  display: none;
}

.required {
  color: #a00;
}

.standardForm #accountTypeHint {
  padding-left: 15px;
  border-left: 1px solid #ccc;
  font-size: 11px;
  border: 0;
  padding-top: 20px;
}

/* partner form */

.checkrow {
  margin: 5px 0 2px 0;
  padding-left: 20px;
}

.formBlock {
  float: left;
  width: 450px;
  position: relative;
}

input[type='checkbox'] {
  margin: 0px;
  padding: 0px;
  margin-right: 8px;
  margin-top: 2px;
}

.checkboxgroup {
  padding-left: 40px;
}

.checkboxgroup label {
  display: block;
  text-align: left;
  padding: 3px 0;
}

.buttonRow,
.buttonrow {
  border-top: 1px dotted #ccc;
  margin-top: 15px !important;
  padding-top: 15px;
}

/* password strength on forms */

#passwordIndicator,
#meterOuter {
  width: 190px;
}

#updateAccountForm #passwordIndicator,
#passwordIndicator {
  float: left;
  font-size: 0.9em;
  height: 23px;
  margin: 27px 0 0 10px;
  display: none;
}

#updateAccountForm #meterOuter,
#meterOuter {
  border: 1px solid;
}

#passwordIndicator #meter {
  background-color: red;
  width: 10px;
  height: 10px;
}

#passwordIndicator #strength {
  width: 10px;
}

#passwordChangeOuter {
  clear: both;
}

#passwordChangeField {
  margin-bottom: 8px;
  float: left;
}

.javascriptRequired {
  display: none;
}

#passwordResetForm #passwordIndicator {
  float: left;
  font-size: 0.9em;
  height: 23px;
  margin: 20px 0 20px 10px;
  display: none;
}

#gotoLogin {
  margin: 10px;
}

/*WEB-12099 Login Page CSS Starts Here*/
.splunk-login-white-box .splunk-login .btn {
  background-color: #E20082;
  border: 1px solid #E20082;
  border-radius: 24px;
  padding: 9.5px 24.5px;
  font-weight: bold;
  font-size: 15px;
}

.splunk-login .btn:hover, .splunk-login .btn:focus {
  color: #fff;
}

.splunk-login h1 {
  font-family: 'splunk_data_sans', Arial, Helvetica, sans-serif !important;
}

.splunk-login .splunk-login-white-box {
  background-color: #fff;
  padding: 40px;
  padding-top: 18px;
  margin-top: 80px;
  padding-bottom: 30px;
  border-radius: 2px;
}

.splunk-login .splunk-login-white-box .submit-btn {
  margin-top: 20px;
}

.splunk-login .splunk-login-white-box .form-control{
  height: 50px;
  border: 1px solid #969DAA;
}

#content .splunk-login p,
#content p.new-font-para {
  font-size: 18px;
  line-height: 1.2;
}

#content .splunk-login p {
  margin-bottom: 0;
  color: #0C1724;
  font-weight: 500;
}
#content .splunk-login #message.successmessage {
  color: #0C1724;
}

#content .splunk-login .errormessage,
#content .splunk-login #message {
  color: #FE3A3A;
  font-size: 16px !important;
  font-weight: bold;
}

.splunk-login .splunk-login-white-box label {
  margin-top: 15px;
  font-size: 16px;
  font-weight: bold;
}

.splunk-login .splunk-login-white-box .other-links {
  font-size: 15px;
}
/*Safari css hacks for macbook pro and iPad Air */
@media screen and (min-color-index: 0) and(-webkit-min-device-pixel-ratio: 0) {
  @media (max-width: 1200px) {
    .manual-validation-section .email-icon {
      margin-left: 45% !important;
      transform: translateX(-50%);
      max-width: 100px;
    }
  }
}

/*Safari css hacks for windows desktop*/
@media only screen and (-webkit-min-device-pixel-ratio: 1) {
  #singleColumn.span-12 {
    width: 100% !important;
    float: left;
  }
}

@media (max-width: 1200px) {
  .splunk-login .splunk-login-white-box {
    margin-top: 18px;
  }

  #singleColumn.span-12 {
    width: 100% !important;
    float: left;
  }
}

@media (max-width: 767px) {
  .splunk-login .btn,
  .splunk-login .container {
    width: 100%;
  }
}

.splunk-login .splunk-login-white-box .other-links>div {
  margin-bottom: 10px;
}
.new-form-elements label {
  font-size: 18px;
}

label.form-text-label {
  text-align: right;
  font-size: 18px;
  font-family: 'splunk_data_sans', Arial, Helvetica, sans-serif;
  font-weight: 600;
  color: #474444;
  padding-right: 0;
  display: inline-block;
  position: relative;
  top: 5px;
  width: 230px;
  padding-top: 0;
}

.form-input-text {
  font-size: 16px !important;
  font-family: 'splunk_data_sans', Arial, Helvetica, sans-serif;
  color: #97999b !important;
  position: relative;
}

.new-form-elements label.checkbox-label {
  font-size: 14px;
  color: #666666;
  display: inline-block;
  line-height: 18px;
  padding-left: 20px;
  position: relative;
}

/*WEB-12130 CSS Starts Here*/
.splunk-login .splunk-login-white-box .or {
  color: #999999;
  font-weight: bold;
  margin-top: 20px;
}

.splunk-login .splunk-login-white-box .btn-bottom-space {
  margin-bottom: 24px;
}

body.top-bottom-nospace #topCap.minimal,
body.top-bottom-nospace .footer,
body.top-bottom-nospace .push {
  height: 0;
}

body.top-bottom-nospace .push {
  padding-top: 0;
}

.form-element-list h2.activate-account-email {
  margin-bottom: 43px !important;
  font-family: 'splunk_data_sans', Arial, Helvetica, sans-serif;
  font-size: 45px;
  font-weight: 800;
  letter-spacing: normal;
  margin-top: 37px;
}

a.activate-account-link {
  font-size: 16px;
  color: #474444;
  font-family: 'splunk_data_sans', Arial, Helvetica, sans-serif;
  text-decoration: none;
  position: relative;
  left: 17%;
}

.activate-account-log-in-button {
  margin-top: 7px;
  margin-bottom: 14px !important;
}

.activate-account-spinning-gears {
  vertical-align: middle;
  width: 20%;
  height: auto;
  margin-right: 20px;
  display: inline-block;
}

.activate-account-spinning-gears.spinning {
  animation-name: ckw;
  animation-duration: 15.5s;
  animation-iteration-count: infinite;
  transform-origin: 50% 50%;
  -webkit-animation-timing-function: linear;
  /* Chrome, Safari, Opera */
  animation-timing-function: linear;
}

.activate-account-spinning-gears.spinning-ccw {
  animation-name: cckw;
  animation-duration: 15.5s;
  animation-iteration-count: infinite;
  transform-origin: 50% 50%;
  -webkit-animation-timing-function: linear;
  /* Chrome, Safari, Opera */
  animation-timing-function: linear;
}

@keyframes ckw {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes cckw {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}

a.activate-account-email-link {
  text-decoration: none;
}

.activate-account-gears-container .activate-account-gears {
  display: inline-block;
  position: relative;
  width: 70px;
}

.activate-account-gears-container .activate-account-gears svg {
  height: 60px;
}

.activate-account-gears-container .activate-account-gears svg:first-child {
  width: 50%;
}

.activate-account-gears-container .activate-account-gears svg:nth-child(2) {
  width: 30%;
  position: absolute;
  right: -6px;
  top: -5px;
}

.activate-account-gears-container .activate-account-gears svg:nth-child(3) {
  position: absolute;
  width: 30%;
  right: -3px;
  top: 23px;
}

.activate-account-gears-container h3 {
  display: inline-block;
  font-size: 18px;
  color: #e87722;
  font-family: 'splunk_data_sans';
  position: relative;
  margin: 0 0;
  font-weight: 600;
  letter-spacing: 1px;
  line-height: 1.2;
}

.activate-account-gears-container .gears-text-green {
  color: #65a637;
}
p {
  font-family: "splunk_data_sans",Helvetica,Arial,sans-serif !important;
  font-size: 15px;
}
p.validation-text {
  font-family: 'splunk_data_sans', Arial, Helvetica, sans-serif;
  font-size: 16px !important;
  color: #97999b !important;
  position: relative;
  top: 5px;
  float: left;
}

#match-text {
  color: #65a637 !important;
}

input.match {
  border-color: #65a637;
}

.special-button {
  background-color: #ffffff;
  border-color: #cccccc;
  position: relative;
}

.special-button span.caret {
  position: relative;
  left: 50%;
  color: #000000;
}

.btn.special-button:hover,
.blue-btn.special-button:hover {
  background: transparent;
}

.btn.special-button:focus,
.blue-btn.special-button:focus {
  background: transparent;
  border-color: #66afe9;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
}

.activate-account-gears-table td {
  vertical-align: middle;
}

@media (max-width: 610px) {
  .activate-account-gears-table td {
    vertical-align: top;
  }
  .activate-account-gears-container h3 {
    padding-top: 0;
  }
  .activate-account-gears-container .activate-account-gears {
    top: 8px;
  }
}

@media only screen and (max-width: 767px) {
  label.form-text-label {
    text-align: left;
    right: 0;
  }
  label.form-input-text {
    right: 0;
  }
  .validation-text {
    margin-left: 10px;
  }
  .form-element-list .row {
    margin-left: -8px;
    margin-right: -8px;
  }
}

@media only screen and (min-width: 768px) {
  .special-button {
    right: 50px;
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .form-text-label {
    text-align: right;
    font-size: 18px;
    font-family: 'splunk_data_sans', Arial, Helvetica, sans-serif;
    color: #474444;
    display: initial;
    right: 0;
    width: 0;
    position: relative;
    top: 5px;
  }
  .form-input-text {
    font-size: 16px;
    color: #97999b;
    position: relative;
    right: 0;
  }
  .activate-account-gears svg:first-child {
    width: 6%;
  }
  .activate-account-gears svg:nth-child(2) {
    position: relative;
    width: 4%;
    top: -10px;
    bottom: 21px;
    right: 21px;
  }
  .activate-account-gears svg:nth-child(3) {
    position: relative;
    width: 4%;
    right: 61px;
    top: 11px;
  }
  .activate-account-gears h3 {
    font-size: 21px;
    left: 50px;
    bottom: 28px;
    margin-right: 33px;
  }
  .activate-account-gears .gears-text-green {
    color: #65a637;
  }
  a.activate-account-email-link {
    font-size: 15px;
  }
  .activate-account-form {
    padding: 0 10px;
  }
  .special-button {
    position: initial;
  }
  .btn.special-button:hover,
  .blue-btn.special-button:hover {
    text-decoration: none;
    background: transparent;
  }
}

@media only screen and (min-device-width: 767px) and (max-device-width: 1025px) {
  .activate-account-form-button div {
    margin-left: 23.666667%;
  }
  .activate-account-gears svg:nth-child(3) {
    position: relative;
    width: 3%;
    right: 70px;
    top: 15px;
  }
  .activate-account-gears .special-button {
    right: 22%;
  }
  .activate-account-gears .button-two {
    position: relative;
    left: 14%;
  }
  .activate-account-gears .checkbox-label {
    position: relative;
    left: 20%;
  }
}

@media (min-width: 768px) and (max-width: 1199px) {
  .checkbox-label {
    position: relative;
    left: 20px;
  }
  .col-sm-offset-2 {
    margin-left: 16.5667% !important;
  }
}

.form-element-list .content {
  padding-top: 25px;
}

/*Bootstarp CSS override */

.form-element-list {
  text-align: left;
}

.generic-section {
  font-family: 'splunk_data_sans', Arial, Helvetica, sans-serif;
  line-height: 23px;
  padding-bottom: 50px;
  padding-top: 68px;
}

section {
  border-bottom: medium none !important;
}

.btn.special-button {
  background-color: #ffffff;
  border-color: #cccccc;
  position: relative;
  height: 34px;
}

.special-button span.caret {
  position: relative;
  left: 50%;
  color: #000000;
}

.btn.special-button:hover,
.blue-btn.special-button:hover {
  background: transparent;
}

.btn.special-button:focus,
.btn.special-button:active,
.blue-btn.special-button:focus {
  background: none !important;
  border-color: #66afe9;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
}

.dropdown.selct-dropdown {
  display: block;
}

#signupOuter .inputBox,
#signupOuter .inputError {
  width: 100%;
  margin-top: 0;
}

#signupOuter.generic-section {
  padding: 0;
  margin: 0;
}

.form-elements-list .content {
  padding-top: 25px;
}

.terms-accept-cnt {
  position: relative;
  margin-bottom: 0;
}

.terms-accept-cnt .checkbox-label {
  margin: 0 0;
  margin-bottom: 10px;
  padding-top: 0;
  font-weight: normal;
}

.terms-accept-cnt .checkbox {
  margin: 0;
  padding: 0;
  vertical-align: middle;
  margin-left: -20px;
  position: relative;
  top: -1px;
}

#statesRowOuter {
  display: none;
}

#signupOuter .selectpicker {
  position: relative;
  width: 100%;
}

#signupOuter .bootstrap-select.btn-group .btn .caret {
  border: none !important;
  right: 20px !important;
  margin-top: -3px !important;
  color: #000;
}

.cust-select-dropdown .caret:before {
  top: 0;
  border-top-color: #000;
}

#signupOuter div.btn-group.bootstrap-select .dropdown-menu .dropdown-menu.inner {
  height: 320px;
  overflow-x: hidden;
}

#signupOuter div.btn-group.bootstrap-select>.dropdown-menu {
  left: auto;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.176);
}

#signupOuter div.btn-group.bootstrap-select button.btn {
  height: 34px;
}

#signupOuter div.btn-group.bootstrap-select button.btn.inputError,
#signupOuter .inputError,
.form-control.inputError {
  background: none;
  border: 1px solid red;
}

#signupOuter div.btn-group.bootstrap-select button.btn:focus,
#signupOuter div.btn-group.bootstrap-select button.btn:active {
  border-color: #66afe9 !important;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px rgba(102, 175, 233, 0.6) !important;
  outline: 0 none;
}

#signupOuter div.btn-group.bootstrap-select .dropdown-menu {
  font-size: 14px !important;
}

#signupOuter div.btn-group.bootstrap-select .dropdown-menu li a:hover,
#signupOuter div.btn-group.bootstrap-select .dropdown-menu li a:focus {
  background-color: #f5f5f5;
}

@media (max-width: 991px) {
  #content .container {
    width: auto;
  }
  .article-header-section,
  .form-element-list {
    padding-top: 20px;
  }
  .form-element-list h2,
  .article-header-section h2 {
    margin-top: 0px;
    font-size: 24px !important;
  }
  .form-element-list .content,
  #content .form-element-list p,
  #content .article-header-section p,
  #content p.new-font-para,
  .error-msg-box-container.col-sm-12,
  .error-msg-box-container.col-sm-8 {
    font-size: 15px;
    line-height: 22px;
  }
  .new-form-elements .btn,
  .form-element-list .content .btn {
    font-size: 15px;
  }
  .express-lane-hero .overlay-image {
    background-image: none !important;
  }
}

@media (max-width: 767px) {
  #signupOuter div.btn-group.bootstrap-select>.dropdown-menu,
  #signupOuter .selectpicker {
    right: 0;
  }
}

/*WEB-12152 Verify Your Email CSS Starts Here*/

.generic-section h2,
.article-header-section h2 {
  padding-top: 0px;
  padding-bottom: 0px;
  margin-top: 0px;
  margin-bottom: 37px;
  text-align: center;
  font-size: 45px;
}

.form-element-list h2,
.article-header-section h2 {
  text-align: left;
  font-family: 'splunk_data_sans', Arial, Helvetica, sans-serif;
  font-weight: 800;
  margin-top: 0px;
  margin-bottom: 5px;
}

.article-header-section {
  padding-top: 38px;
}

.signup_header_article {
  padding-top: 0 !important;
}

#content .form-element-list p,
#content .article-header-section p,
#content p.new-font-para {
  color: #474444;
  font-family: 'splunk_data_sans';
}

@media (min-width: 992px) {
  #content .form-element-list p,
  #content .article-header-section p,
  .error-msg-box-container.col-sm-12,
  .error-msg-box-container.col-sm-8 {
    font-size: 18px;
    line-height: 27px;
  }
  .error-msg-box-container .text-aliangment {
    width: 594px !important;
  }
}

.article-title h2 {
  margin-bottom: 41px;
}

div.wrapper footer.page-footer a,
div.wrapper footer.page-footer {
  font-style: normal;
  font-size: 15px;
  font-weight: 400;
  line-height: 170%;
  text-align: right;
  color: #656C76;
}

/*WEB-12152 Verify Your Email CSS Ends Here*/
@media (max-width: 1110px) {
  div.wrapper footer.page-footer {
    padding: 15px 11px 16px 11px !important;
  }
  div.wrapper footer.page-footer a,
  div.wrapper footer.page-footer {
    font-size: 12px !important;
  }

}
@media (min-width: 1024px) {
  label.form-text-label {
    direction: rtl;
    white-space: nowrap;
  }
  .error-msg-box-container .content-column,
  .error-msg-box-container .text-aliangment {
    padding: 0;
  }
}

@media only screen and (max-width: 767px) {
  .form-element-list .row {
    margin-left: -8px;
    margin-right: -8px;
  }
  .new-form-elements .btn,
  .form-element-list .content .btn,
  .splunk-login .btn {
    width: 100%;
  }
}

@media (max-width: 767px) {
  [class^='col-'],
  [class*='col-'] {
    padding-left: 8px !important;
    padding-right: 8px !important;
  }
}

#content .form-element-list p.activate-account-funnel-link {
  font-size: 16px;
  font-family: 'splunk_data_sans';
  text-decoration: none;
  position: relative;
  margin-top: 25px;
}

.panel-error-msg-cnt.panel-danger>.panel-heading {
  color: #982b26;
  background-color: #eab6b8;
  font-weight: bold;
}

.panel-error-msg-cnt.panel-danger>.panel-body {
  margin-left: 25px;
}

.panel-error-msg-cnt.panel-danger {
  background-color: #f2dede;
  padding: 0 !important;
  border-color: #a75a64;
}

.error-msg-text {
  color: red;
  font-size: 16px;
  margin-bottom: 15px;
}
