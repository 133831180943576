
.splunk-login-white-box {
  .h-captcha {
    text-align: left;
    margin-top: 25px;
  }
}
.h-captcha {
  text-align: center;
}
.g-recaptcha {
  display: none;
}
div:has(div>iframe[title*="recaptcha"]) {
  pointer-events: none;
  padding: 100px;

  div {
    pointer-events: none;
  }

  iframe {
    pointer-events: all;
  }
}
iframe[title*="recaptcha"] {
  pointer-events: all;
}
.splunk-login-white-box h1 {
  font-size: 36px !important;
  margin: 20px 0 10px 0;
}

.splunk-login .btn:hover {
  background-color: #a8005b !important;
}
.splunk-login .btn {
  background-color: #ce0070;
}

.splunk-login .btn:disabled {
  background-color: #fff;
  color: #333;
  border: 1px solid #333;
}

.splunk-login-white-box label {
  width: 100% !important;
}
.splunk-login-white-box {
  max-width: 555px;
  margin-left:auto !important;
  margin-right:auto !important;
  position: relative !important;
}

.top-line {
  position: absolute !important;
  width: 100%;
  height: 5px;
  top: 0;
  left: 0;
  background: linear-gradient(90deg,#f99d1c,#f99c1c 10.02%,#f4781f 29.97%,#f16221 43.07%,#f05a22 58.06%,#ee1d62 76.03%,#ed0080 97.09%)!important;
}

.splunk-submit button {
  width: 100% !important;
  font-style: normal !important;
}
.splunk-submit button span {
  font-style: normal !important;
}

.splunk-submit button:disabled {
  background-color: #fff;
  color: #333;
  border: 1px solid #333
}

.splunk-submit button svg {
  width: 30px !important;
  height: 30px !important;
  background-color: none !important;
}

#themeSelect {
  margin-bottom: 0 !important;
  max-width: 555px;
}

.links #themeSelect.black-theme-form-container {
  width: calc(50% - 15px);
  border-color: #dadce0;
  padding: 20px;
  margin: 0 15px 10px 0;
  display: inline-block;
}

#login-form {
  position: relative;
}

#themeSelect.black-theme-form-container {
  padding: 5px 0 0 0 !important;
  border-top: none !important;
  background: linear-gradient(
    90deg,
    #f99d1c 0%,
    #f99c1c 10.02%,
    #f4781f 29.97%,
    #f16221 43.07%,
    #f05a22 58.06%,
    #ee1d62 76.03%,
    #ed0080 97.09%
  ) !important;
}

#themeSelect.black-theme-form-container #contentSection {
  background-color: #fff !important;
  padding: 57px 103px 75px 121px;
}

#titleHead {
  width: 283px;
  height: 23px;
  margin: 0 auto;
  font-family: splunk_data_sans, Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 21px;
  line-height: 110%;
  text-align: center;
  color: #0c1724;
}

#contentSection input[type='text'],
#contentSection input[type='password'] {
  border: 1px solid #969daa;
  width: 331px !important;
  border-radius: 3px;
  height: 45px !important;
}
#contentSection #login-form .splunk-form .globalize {
  display: block;
}
#contentSection .input-container {
  position: relative;
  display: flex;
  flex-direction: column;
  max-height: 45px !important;
}

#contentSection .input-container input:disabled,
#contentSection .input-container input:disabled+label {
  opacity: 0.7;
}

#contentSection .input-container .pwd-toggle-invisible {
  display: none !important;
}

#contentSection .input-container .pwd-toggle-text,
#contentSection .input-container .pwd-toggle-password {
  box-shadow: none !important;
  background: none !important;
  border: none !important;
  position: absolute !important;
  z-index: 10000;
  right: 10px;
  top: 10px;
  color: #666 !important;
  font-size: 16px !important;
  font-weight: normal;
}

.splunk-login .main-login .login-error {
  position: absolute;
  left: 50%;
  margin-top: -10px;
  transform: translate(-50%, 0);
  font-size: 14px !important;
  text-align: center;
  background: #D11B04;
  color: #fff;
  padding: 5px 40px 5px 10px;
  -webkit-box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.75);
  box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.75);
  z-index: 10000;

  button {
    background: none;
    border-left: 1px solid #de7d7d !important;
    border: none;
    color: #fff;
    font-size: 15px;
    position: absolute;
    right: 3px;
    top: 5px;
    padding: 0 10px;
  }
}

#contentSection .input-container .pwd-toggle-text:hover,
#contentSection .input-container .pwd-toggle-password:hover {
  opacity: 0.9;
}

#contentSection .input-container .meIWG[data-inline] {
  width: 100% !important;
  height: 5px !important;
}

#contentSection .input-container .meIWG .hBoXju {
  display: none !important;
}

@media (max-width: 765px) {
  .splunk-login .main-login .login-error {
    width: 330px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1199px) {
  .splunk-login .main-login .login-error {
    width: 610px;
  }
  #contentSection .input-container {
    margin-bottom: 28px;
  }

  #themeSelect.black-theme-form-container {
    padding: 60px 40px;
  }

  .links #themeSelect.black-theme-form-container {
    width: 100%;
    display: block;
    margin-right: 0;
  }

  #titleHead.splunk2-h4 {
    font-size: 21px;
  }

  #content .body-small {
    font-size: 15px;
  }

  .splunk-btn.sp-btn-borderless {
    margin-right: 10px;
  }
}

@media (min-width: 364px) and (max-width: 1199px) {
  #contentSection .input-container#username-container {
    margin-bottom: 35px;
  }

  #contentSection .input-container#password-container {
    margin-bottom: 32px;
  }

  #titleHead.splunk2-h4 {
    font-size: 20px;
    margin-bottom: 41px;
  }

  #themeSelect.black-theme-form-container #contentSection {
    padding-left: 17px;
    padding-right: 17px;
    width: 363px;
    height: 461px;
  }

  #themeSelect {
    max-width: 362px;
  }
}

@media (min-width: 1200px) {
  #contentSection .input-container#username-container {
    margin-bottom: 31px;
  }

  #contentSection .input-container#password-container {
    margin-bottom: 28px;
  }

  .splunk-form input.ioLPsn {
    min-height: 40px !important;
  }

  #contentSection #login-form h4 {
    margin-bottom: 42px;
  }
  .splunk-login-white-box {
    .h-captcha {
      text-align: left;
      margin-top: 25px;
    }
  }
}
.mk-banner-slot {
  .login-failover-img {
    height: 100%;
    width: 100% !important;
  }
}
@media screen and (min-width: 1024px) {
  .links .actions a {
    display: inline-block;
  }
}

@media (min-width: 364px) and (max-width: 1199px) {
  .mkt-banner-slot .splunk-image-fullwidth, .mkt-banner-slot .text-image,
  .mkt-banner-slot .speakerDetail-spacer {
    width: 280px !important;
  }
}
/*Marketing banners*/
@media screen and (min-width: 1024px) {
  .splunk-login .main-login .login-error {
    width: 810px;
  }
  .mkt-banner-slot .splunk-image-fullwidth, .mkt-banner-slot .text-image,
  .mkt-banner-slot .speakerDetail-spacer {
    width: 375px !important;
  }
  .login-links-slot .splunk-tni-wrapper {
    width: 1100px
  }
}

/* Bootrap Overrides */
.tooltip {
  opacity: 0.9;
  .tooltip-content p {
    font-size: 11px;
    font-family: splunk_data_sans, Helvetica, Arial, sans-serif;
  }

  .tooltip-content table td {
    color: rgb(216, 6, 6);
    font-weight: bold;
  }
}


