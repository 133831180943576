@import 'colors';

.button {
  vertical-align: middle;
  &, a { color: color('primary-white') }
  background-color: color('primary-black');
  border: 1px solid color('primary-black');
  border-radius: 2px;
  font-size: 16px;
  display: inline-block;
  padding: 10px 20px;
  line-height: 100%;
  white-space: nowrap;
  cursor: pointer;
  text-align: center;
  font-weight: bold;
  transition: background-color .5s;
}
.button:focus {
  outline: none;
}
.button:hover {
  text-decoration: none;
  color: color('primary-white');
}

.button--primary {
  background-color: color('primary-black');
  color: white;
  &:hover {
    color: white;
    background-color: color('black-hover');
  }
  &:focus {
    text-decoration: none;
    color: white;
  }
}

.button--disabled {
  pointer-events: none;
  cursor: not-allowed;
  background-color: color('primary-black');
  color: white;
  opacity: 0.4;

  &:focus {
    text-decoration: none;
    color: white;
  }
}

.splunk-btn {
  transition: background-color .5s;
  display: inline-block;
  font-weight: bold;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 10px 20px;
  font-size: 16px;
  line-height: 100%;
  border-radius: 2px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-right: 10px;
  margin-bottom: 10px;
}
.sp-btn-blue {
  color: color('primary-white');
  background-color: color('light-blue');
  border-color: color('darker-blue');
  &:hover {
    color: color('primary-white');
    background-color: color('darker-blue');
    border-color:  color('darker-blue');
  }
  
}
.sp-btn-blue-hover {
    color: color('primary-white');
    background-color: color('darker-blue');
    border-color:  color('darker-blue');
}
.sp-btn-green {
  color: color('primary-white');
  background-color:  color('primary-green');
  border-color: color('dark-green');
}
.sp-btn-green-hover {
  background-color: deeppink;
  border-color: color('secondary-green');
  color: color('primary-white');
}
.sp-btn-black {
  color: color('primary-white');
  background-color: color('primary-black');
  border-color: color('primary-black');
}
.sp-btn-black-hover {
  color: color('primary-white');
  background-color: color('dark-grey');
  border-color: color('primary-black');
}
.sp-btn-blue-hollow {
  color: color('light-blue');
  background-color: transparent;
  border-color:  color('light-blue');
  &:hover {
    color: color('primary-white');
    background-color: color('light-blue');
    border-color: color('primary-white');
  }
  
}
.sp-btn-blue-hollow-hover {
  color: color('primary-white');
  background-color: color('light-blue');
  border-color: color('primary-white');
}
.sp-btn-green-hollow {
  color: color('primary-green');
  background-color: transparent;
  border-color:  color('primary-green');
}
.sp-btn-green-hollow-hover {
  color: color('primary-white');
  background-color: color('primary-green');
  border-color: color('primary-green');
}
.sp-btn-black-hollow {
  color: color('primary-black');
  background-color: transparent;
  border-color: color('primary-black');
  &:hover {
    color: color('primary-white');
    background-color: color('primary-black');
    border-color: color('primary-black');
  }
}
.sp-btn-black-hollow-hover {
  color: color('primary-white');
  background-color: color('primary-black');
  border-color: color('primary-black');
}
.sp-btn-blue-hollow.light-bg:hover {
  border-color: color('dark-blue');
  background-color: color('dark-blue');
  color: color('light-black');
}
.sp-btn-blue-hollow-hover.light-bg {
  border-color: color('dark-blue');
  background-color: color('dark-blue');
  color: color('light-black');
}
.sp-btn-gray-hollow {
  color: color('splunk-grey');
  background-color: transparent;
  border-color: color('splunk-grey');
}
.btn {
  color: #fff;
}