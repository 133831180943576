
$font-proxima-nova: 'proxima_nova';
$weight-light: 200;
$weight-normal: normal;
$weight-bold: bold;
$weight-semibold: 600;
$weight-extrabold: 800;
$weight-black: 900;

@font-face {
  font-family: $font-proxima-nova;
  src: url('../fonts/proximanova/proximanova-regularitalic-webfont.eot');
  src: url('../fonts/proximanova/proximanova-regularitalic-webfont.eot?#iefix') format('embedded-opentype'), url('../fonts/proximanova/proximanova-regularitalic-webfont.woff2') format('woff2'), url('../fonts/proximanova/proximanova-regularitalic-webfont.woff') format('woff'), url('../fonts/proximanova/proximanova-regularitalic-webfont.ttf') format('truetype'), url('../fonts/proximanova/proximanova-regularitalic-webfont.svg#proxima_novaregular_italic') format('svg');
  font-weight: normal;
  font-style: italic;
}

@font-face {
	font-family: 'Glyphicons Halflings';
	src: url('../fonts/glyphicons-halflings-regular.eot');
	src: url('../fonts/glyphicons-halflings-regular.eot?#iefix')
		 format('embedded-opentype'),
		 url('../fonts/glyphicons-halflings-regular.woff')
		 format('woff'),
		 url('../fonts/glyphicons-halflings-regular.ttf')
		 format('truetype'),
		 url('../fonts/glyphicons-halflings-regular.svg#glyphicons_halflingsregular')
		 format('svg');
}
@font-face {
  font-family:'icomoon';
  src:url('../fonts/icomoon/icomoon.eot');
  src:url('../fonts/icomoon/icomoon.eot') format('embedded-opentype'), url('../fonts/icomoon/icomoon.ttf') format('truetype'), url('../fonts/icomoon/icomoon.woff') format('woff'), url('../fonts/icomoon/icomoon.svg') format('svg');
  font-weight:normal;
  font-style:normal;
}


$font-splunkDataSans: 'splunk_data_sans';

@font-face{
	font-family: $font-splunkDataSans;
	font-weight: 200;
	src: url('../fonts/splunk_data_sans/SplunkDataSans_Lt.woff');
}

@font-face{
	font-family: $font-splunkDataSans;
	font-weight: normal;
	src: url('../fonts/splunk_data_sans/SplunkDataSans_Rg.woff');
}

@font-face{
	font-family: $font-splunkDataSans;
	font-weight: 600;
	src: url('../fonts/splunk_data_sans/SplunkDataSans_Md.woff');
}

@font-face{
	font-family: $font-splunkDataSans;
	font-weight: bold;
	src: url('../fonts/splunk_data_sans/SplunkDataSans_Bd.woff');
}

@font-face{
	font-family: $font-splunkDataSans;
	font-weight: 800;
	src: url('../fonts/splunk_data_sans/SplunkDataSans_XBd.woff');
}

@font-face{
	font-family: $font-splunkDataSans;
	font-weight: 900;
	src: url('../fonts/splunk_data_sans/SplunkDataSans_Blk.woff');
}

@font-face {
    font-family: splunk_list;
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/splunk_list_icons.woff') format("woff");
    font-display: swap
}